<template>
  <div class="page">
    <section id="banner" class="align-middle">
      <banner-image-overlay></banner-image-overlay>
      <h2 class="title">FAQs</h2>
    </section>
    <div class="container 75%">
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">1. How Can I start?</h3>
        <div class="answer">
          <p><router-link :to="{ name: 'Register' }" target="_blank">Register</router-link>
          in our website, schedule your first lesson for free and start learning</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">2. What are MySpanishNow lessons like?</h3>
        <div class="answer">
          <p>MySpanishNow lessons focus on your goals and needs.
            Our students are travellers, workers or students but all of them
            have their own needs. That's why each lesson is adapted to their
            specific and individual purposes because not everybody learns
            at the same speed.</p>
          <p>The lessons lasts 55 minutes and our teaching method is
            personalized, dynamic, communicative,
            funny, interactive and we can openly say that all our
            students feel confident, supported and motived in all their lessons.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">3. Which communication platform is used?</h3>
        <div class="answer">
          <p><a href="https://whereby.com/" target="_blank">Whereby</a>
            is our main communication platform.
            You don't have to download, install or create an account,
            you can simply join from our website, by clicking in the
            <i>Join Lesson</i> button from your MySpanishNow dashboard.
            With Whereby, we can do all kinds of activities by using share
          screen, chat and others.</p>
          <p>Apart from that, we will also use the email and the Google
            Drive for sharing documents. Remember that learning Spanish
            online is very easy, practical and comfortable.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">4. What do I need for the lesson?</h3>
        <div class="answer">
          <p>Apart from a computer with a webcam, a microphone,
            good speakers or headphones, you also need good
            vibes and a big smile.</p>
          <p>A quite place with good illumination is favourable and
            good Internet connection is essential.</p>
          <p>Please, also check the question <b>3. Which communication platform is used?</b></p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">5. What is the Free lesson about?</h3>
        <div class="answer">
          <p>It is a 35 minutes completely free lesson, that is useful for
            both the student and teacher to get to know each other.</p>
          <p>During this lesson we will check your level and we will discuss
            about  your interests and needs. This first contact is important
            to design your ideal lessons. At the same time, you
            have the opportunity to get acquainted with the way the online
            lessons work.</p>
          <p>Remember: Feel free to ask us any questions that you might have.
            We are here to help you.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">6. Are the lessons individual
          or in group?</h3>
        <div class="answer">
          <p>All lessons are 1-to-1 lessons to cover
            individual needs. We believe that this is the best and most efficient
            way to learn a language.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">7. How do I pay?</h3>
        <div class="answer">
          <p>Payments are made by credit/debit card via our website.
            We use <a href="https://stripe.com/" target="_blank">Stripe</a>
            to handle our payments, which is a safe and easy
            thirdparty payment service.</p>
          <p>Please take in mind that MySpanishNow doesn’t
            take any responsibility of any additional charge
            that your bank could add.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">8. What happens if I don’t
          show up in a lesson?</h3>
        <div class="answer">
          <p>Unfortunately, the lesson would be taken by granted.</p>
          <p>However, you can always cancel/re-schedule a lesson.
            For more information, check the section <b>How can I
            cancel/re-schedule a lesson?</b>
          </p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">9. What happens if I am
          late for the lesson?</h3>
        <div class="answer">
          <p>It can happen to everyone. And if you let me know in
            advance by email, I might be flexible and extend the
            lesson for up to 10 minutes. However,
            keep in mind that it’s not possible to extend the
            lesson much further due to other student’s bookings.</p>
          <p>Remember: Depending on the context and circumstances
            the lesson might not be able to be extended.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">10. Do the lesson Plans expire?</h3>
        <div class="answer">
          <p>Yes, they have an expiration date of six months,
            starting from the day of purchase.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">11. How can I re-schedule
          a lesson?</h3>
        <div class="answer">
          <p>You can cancel/re-schedule a lesson by writing us an email at:
          <a href="mailto:hello@myspanishnow.com" target="_blank">hello@myspanishnow.com</a></p>
          <p>If you get in touch with us, at least 24 hours in advance,
            you will be able to re-schedule the lesson to another day
            and time that suits you better. Otherwise, the lesson would
            be taken for granted.</p>
          <p>Nevertheless, take in mind, that the lesson is not refundable.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">12. How much time do I need
          to improve my Spanish?</h3>
        <div class="answer">
          <p>It all depends on how often you practice your Spanish.
          </p>
          <p>Anyway, with MySpanishNow lessons you would be able to see
            real improvements week-by-week.</p>
          <p>Motivation is essential. That's why one of our goals is to keep you
            motivated during the learning process.
            Remember, the longer you spend using the language the better
            results you will have.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">13. How often is recommended
          to attend Spanish lessons?</h3>
        <div class="answer">
          <p>This depends on your interests and needs, but we
            highly recommend you to take at least one lesson per week.
            Keep in mind, that consistency is the key.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">14. What should I do if
          I can’t attend a lesson?</h3>
        <div class="answer">
          <p>You should send us an email at
            <a href="mailto:hello@myspanishnow.com" target="_blank">hello@myspanishnow.com</a>.
            If you notify us, 24 hours in advance, you will be
            eligible to re-schedule the lesson. Otherwise, the lesson
            would be taken for granted.</p>
          <p>For more information, check section
            <b>11. How can I re-schedule a lesson?</b></p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">15. What happens if there are
          technical issues during the lesson?</h3>
        <div class="answer">
          <p>Firstly, you should have all the technical requirements beforehand.
            Please, check the <b>4. What do I need for the lesson?</b> question for
            further information.</p>
          <p>However, if there are technical issues we may be able to re-schedule
            another lesson or to extend the duration of a future lesson.</p>
          <p>Nevertheless, if the student tries, in some way, to take advantage
            of this situation we will take the lesson for granted.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">16. What happens if the teacher
          cancel my lesson(s)?</h3>
        <div class="answer">
          <p>The teacher will always inform you in advance about her annual leave.
            However, in case that she is sick or in some other similar situation,
            you have the right to re-schedule the lesson(s) or to ask for a full
            refund of those cancelled lessons.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">17. How can I buy a lesson(s) as a gift?</h3>
        <div class="answer">
          <p>You can buy 1, 3 or 5 one-to-one lessons by getting in touch with us via our
            <a href="/contact" target="_blank">website</a>.</p>
          <p>In your message you should include the pack of lesson(s) you would like as well
            as to who it is (name and email). We will then contact you via email to discuss
            about the personalised gift card as well as to send you your invoice.</p>
          <p>For more information about the possible pack of lessons you may check our
            <a href="/#plans" target="_blank">Plans</a> section or get in touch with us</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">18. Do I need to create an account for buying a lesson(s) as a gift?</h3>
        <div class="answer">
          <p>No, you don't need to create any account. Only the person
            receiving the gift has to create an account afterwards for
            being able to join the lesson(s).
          </p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">19. How to ask for a refund?</h3>
        <div class="answer">
          <p>We are really sorry that our lessons didn't met your expectations.
            To ask for a refund of all your unused credits of your first pack bought you can
            contact us at via our website
            <router-link target="_blank" :to="{
              name: 'Contact',
              query: {
                subject: 'Ask for a refund',
                message: 'Hello, I would ask for a refund of all the unused credits of my first pack bought'
              }
            }">Contact page</router-link>
            or via our email
            <a href="mailto:hello@myspanishnow.com" target="_blank">hello@myspanishnow.com</a>
          </p>
          <p>Make sure that your refund is compliant to
            <b>20. How many days do I have to ask for a refund?</b> and
            <b>21. Which Plans are eligible for a refund?</b>
          </p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">20. How many days do I have to ask for a refund?</h3>
        <div class="answer">
          <p>You have 30 days, starting from the day that you bought your first pack.</p>
        </div>
      </section>
      <section class="question-container accordion-close row">
        <h3 class="question" @click="selectedQuestion">21. Which Plans are eligible for a refund?</h3>
        <div class="answer">
          <p>Only your first pack is eligible for a 30-day refund. Subscriptions are not refundable</p>
          <p>Please bear in mind, that you may only ask for a refund on <b>unused credits</b> of your
            <b>first pack</b> bought</p>
        </div>
      </section>
      <section class="contact-us">
        <div class="row">
          <div class="col">
            <h2>Do you want to ask us something else?</h2>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <ul class="actions">
              <li><router-link :to="{ name: 'Contact' }" target="_blank"
                class="button special big">Get in touch</router-link></li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';

export default {
  name: 'FAQ',
  components: {
    BannerImageOverlay,
  },
  methods: {
    selectedQuestion(event) {
      const parent = event.target.parentElement;
      const isClosed = parent.classList.contains('accordion-close');
      this.closeAllQuestions();
      if (isClosed) {
        parent.classList.remove('accordion-close');
        parent.classList.add('accordion-open');
      }
    },
    closeAllQuestions() {
      const closedQuestions = this.$el.getElementsByClassName('question-container accordion-open');
      for (let i = 0; i < closedQuestions.length; i += 1) {
        const question = closedQuestions[i];
        question.classList.remove('accordion-open');
        question.classList.add('accordion-close');
      }
    },
  },
};
</script>

<style scoped>
  .contact-us {
    margin-top: 100px;
    text-align: center;
    margin-bottom: 50px;
  }

  .question-container:first-child {
    margin-top: 50px;
  }

  .question-container.accordion-close .answer {
    display:none;
  }

  .question-container .question {
    cursor: pointer;
  }

  .question-container {
    border: 1px solid transparent;
  }

  .question-container .question:hover,
  .question-container.accordion-open .question {
    background-color: #f32853;
    color: white;
  }

  .question-container.accordion-open {
    border: 1px solid #f32853;
  }

  .question-container .question {
    margin-bottom: 0;
    width: 100%;
  }

  .question-container {
    margin-bottom: 1em;
  }

  .question-container .answer > p:first-child {
    margin-top: 15px;
  }

  .question-container .answer > p {
    margin-bottom: 15px;
  }

  .answer {
    padding-left: 15px;
    padding-right: 15px;
  }
</style>
